import React from 'react'
import { Link } from 'gatsby'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import Burger from './Burger'


export default function Nav() {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="flex">
          <div>
            <Link to="/" className="brand">
              <span className="emoji">🗃️</span> Irena Popova
            </Link>
          </div>
          <div>
            <Burger> </Burger>
          </div>
          <div className="mainNavLinks">
            <Link to="/blog">Articles</Link>
            <Link to="/guides">Links</Link>
            <Link to="/about">About</Link>

            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <button id="dark-mode">
                  <label style={{ fontsize: "20px", padding: "2px" }} htmlFor="A" >

                    <input style={{ display: "none" }}
                      type="checkbox"
                      id="A"
                      onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                      checked={theme === 'dark'}
                    />{' '}
                    {typeof window !== 'undefined' &&
                      localStorage.getItem('theme') === 'dark'
                      ? '🌙'
                      : '☀️'}
                  </label>
                </button>
              )}
            </ThemeToggler>

          </div>
        </div >
      </div >
    </nav >
  )
}
