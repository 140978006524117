import React from 'react'
import Helmet from 'react-helmet'

import favicon from '../../content/images/favicon.png'

import Nav from './Nav'
import Footer from './Footer'


import '../style.css'
import '../main.css'

export default function Layout({ children }) {


  return (
    <>
      <Helmet>
        <link rel="shortcut icon" type="images/favicon.png" href={favicon} />
      </Helmet>
      <Nav />

      <main>{children}</main>
      <Footer />
    </>

  )
}
