import React from 'react'
import { Link } from 'gatsby'


import gatsby from '../../content/images/gatsby.png'
import github from '../../content/images/github.png'

export default function Footer() {
  return (
    <section className="main-footer">
      <footer className="footer container">
        <section className="flex">
          <nav className="footer-links">
            <Link className="linkCard" to="/blog">Articles</Link>
            <Link className="linkCard" to="/guides">Links</Link>
            <a
              className="linkCard"
              href="https://irena.substack.com/subscribe"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email newsletter
          </a>
            <Link className="linkCard" to="/rss.xml">RSS </Link>
            {/** ========DONATE a Ko-fi =========== */}
            <a
              className="linkCard"
              href="https://ko-fi.com/irenapopova"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ko-fi
          </a>
            {/** ========= Patreon Membership ========== */}
            <a
              className="linkCard"
              href="https://www.patreon.com/user?u=39820305"
              target="_blank"
              rel="noopener noreferrer"
            >
              Membership
          </a>
          </nav>

          <nav className="flex">

            {/* <a
            href="https://www.gatsbyjs.org/"
            title="Built with Gatsby"
            target="_blank"
            rel="noopener noreferrer"
            className="img"
          >
            <img src={gatsby} className="footer-img" alt="Gatsby" />
          </a> */}

            <a
              href="https://github.com/irenapopova"
              title="Open-source on GitHub"
              target="_blank"
              rel="noopener noreferrer"
              className="img"
            >
              <img src={github} className="footer-img" alt="GitHub" />
            </a>

          </nav>

        </section>
      </footer >
    </section>
  )
}
