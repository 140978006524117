import React from "react";
/* import { slide as Menu } from "react-burger-menu"; */
import { Link } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from 'react';


export default Burger => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // if (isOpen) {
    //   document.body.classList.add("noScroll")
    // } else {
    //   document.body.classList.remove("noScroll")
    // }


    document.body.classList.toggle("noScroll", isOpen);

  }, [isOpen])

  return (

    <div>

      {/* <div className="burgerIcon" onClick={() => { document.body.classList.toggle("noScroll", !isOpen); setIsOpen(!isOpen) }} >
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="2x" />
  </div> */}

      <div className="burgerIcon" onClick={() => { setIsOpen(!isOpen) }} >
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="2x" />
      </div>

      <div className={isOpen ? "burgerNavShow" : "burgerNavHide"} >

        <Link className="menu-item" to="/">
          Home
        </Link>

        <Link className="menu-item" to="/blog">
          Articles
        </Link>

        <Link className="menu-item" to="/guides">
          Links
      </Link>

        <Link className="menu-item" to="/about">
          About
      </Link>
      </div>
    </div>
  );
}
